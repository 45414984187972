import React from "react";
import '../../styles/nav.css';
import { hexToRgb } from '../../scripts/tools.js';

import Button from "@mui/material/Button";

import PersonIcon from '@mui/icons-material/Person';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandymanIcon from '@mui/icons-material/Handyman';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

var about_color = getComputedStyle(document.documentElement).getPropertyValue('--about-icon-color').replace('rgb(', '').replace(')', '');
var about_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--about-icon-color-darker').replace('rgb(', '').replace(')', '');
var projects_color = getComputedStyle(document.documentElement).getPropertyValue('--projects-icon-color').replace('rgb(', '').replace(')', '');
var projects_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--projects-icon-color-darker').replace('rgb(', '').replace(')', '');
var skills_color = getComputedStyle(document.documentElement).getPropertyValue('--skills-icon-color').replace('rgb(', '').replace(')', '');
var skills_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--skills-icon-color-darker').replace('rgb(', '').replace(')', '');
var contact_color = getComputedStyle(document.documentElement).getPropertyValue('--contact-icon-color').replace('rgb(', '').replace(')', '');
var contact_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--contact-icon-color-darker').replace('rgb(', '').replace(')', '');

// In production, the lines above return the color in HEX format not RGB. We can check if the values start with a # and if so convert them to RGB
if (about_color.startsWith('#')) {
    about_color = hexToRgb(about_color);
    about_color_darker = hexToRgb(about_color_darker);
    projects_color = hexToRgb(projects_color);
    projects_color_darker = hexToRgb(projects_color_darker);
    skills_color = hexToRgb(skills_color);
    skills_color_darker = hexToRgb(skills_color_darker);
    contact_color = hexToRgb(contact_color);
    contact_color_darker = hexToRgb(contact_color_darker);
}

const button_style = {
    borderRadius: '9999em 9999em 9999em 9999em',
    minWidth: '130px',
    maxWidth: '130px',
    textTransform: 'none',
}

const about = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + about_color + ', 0.2)',

    '&:hover': {
        backgroundColor: 'rgba(' + about_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + about_color + ', 0.5)',
    }
}

const projects = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + projects_color + ', 0.2)',
    '&:hover': {
        backgroundColor: 'rgba(' + projects_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + projects_color + ', 0.5)',
    }
}

const skills = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + skills_color + ', 0.2)',
    '&:hover': {
        backgroundColor: 'rgba(' + skills_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + skills_color + ', 0.5)',
    }
}

const contact = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + contact_color + ', 0.2)',
    '&:hover': {
        backgroundColor: 'rgba(' + contact_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + contact_color + ', 0.5)',
    }
}

export default function Nav() {
    return (
        <div className='nav'>
            <Button sx={[button_style, about]} startIcon={<PersonIcon className='about-icon'/>} onClick = {() => {scrollToAbout();}}> About </Button>
            <Button sx={[button_style, projects]} startIcon={<RocketLaunchIcon className='projects-icon'/>} onClick = {() => {scrollToProjects();}}> Projects </Button>
            <Button sx={[button_style, skills]} startIcon={<HandymanIcon className='skills-icon'/>} onClick = {() => {scrollToSkills();}}> Skills </Button>
            <Button sx={[button_style, contact]} startIcon={<MailOutlineIcon className='contact-icon'/>} onClick = {() => {scrollToContact();}}> Contact </Button>
        </div>
    );

    function scrollToAbout() {
        // Scroll to the about section
        var about = document.getElementById('about');
        about.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    function scrollToProjects() {
        // Scroll to the projects section
        var projects = document.getElementById('projects');
        projects.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    function scrollToSkills() {
        // Scroll to the skills section
        var skills = document.getElementById('skills');
        skills.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    function scrollToContact() {
        // Scroll to the contact section
        var contact = document.getElementById('contact');
        contact.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
}