import React from "react";
import '../../styles/sections.css';
import { hexToRgb } from '../../scripts/tools.js';

import { SvgIcon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ReactComponent as LinkedIn } from '../../static/icons/icons8-linkedin-color/icons8-linkedin-96.svg';
import { ReactComponent as Github } from '../../static/icons/icons8-github-color/icons8-github-96.svg';
import { ReactComponent as Email } from '../../static/icons/icons8-mail-color/icons8-mail-96.svg';

import image_1 from '../../static/images/tropical-island.jpeg';
import image_2 from '../../static/images/street-cyberpunk.jpeg';
import image_3 from '../../static/images/port-roman-city.jpeg';

var card_background = getComputedStyle(document.documentElement).getPropertyValue('--color-background-light').replace('rgb(', '').replace(')', '');
var card_text_color_primary = getComputedStyle(document.documentElement).getPropertyValue('--color-heading').replace('rgb(', '').replace(')', '');
var card_shadow_color = getComputedStyle(document.documentElement).getPropertyValue('--color-animation-text').replace('rgb(', '').replace(')', '');

// In production, the lines above return the color in HEX format not RGB. We can check if the values start with a # and if so convert them to RGB
if (card_background.startsWith('#')) {
    card_background = hexToRgb(card_background);
    card_text_color_primary = hexToRgb(card_text_color_primary);
    card_shadow_color = hexToRgb(card_shadow_color);
}

const linkedin = 'https://www.linkedin.com/in/francisco-javier-barranco-tena/';
const github = 'https://github.com/JaviBT';
const email = 'mailto:javibarrancotena@icloud.com';

const contact_card_container = {
    alignSelf: 'center',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    width: '90%',
    color: 'rgb(' + card_text_color_primary + ')',

    paddingTop: '15px',
}

const contact_card = {
    width: '95%',
    height: '100px',

    backgroundColor: 'rgb(' + card_background + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '5px',
    marginBottom: '15px',
    boxShadow: '2px 2px 10px 3px rgba(' + card_shadow_color + ', 0.2)',
    borderRadius: '10px',
    
    paddingTop: '10px',

    // Hover effect: displace all the card up and to the left and make the shadow more intense
    // Make it smoother by adding more time to the transition
    '&:hover': {
        transform: 'translate(-5px, -5px)',
        transition: 'transform 0.3s ease-in-out',
        boxShadow: '2px 2px 15px 8px rgba(' + card_shadow_color + ', 0.75)',
    },
}

const contact_card_action = {
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'hidden',
}

const contact_card_icon = {
    marginLeft:'30px',
}

function svg_scaleup(scale) {
    return (
        {
        transform: 'scale(' + scale + ')',
    }
    )
}

export default function Contact() {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$%&!?';
    const noCaps_characters = 'abcdefghijklmnopqrstuvwxyz@#$%&!?';

    const original_title = 'Contact';

    const [title, setTitle] = React.useState(original_title);

    const handleMouseOver = () => {
        let iterations = 0;

        const interval = setInterval(() => {
            let new_title = '';

            for (let i = 0; i < original_title.length; i++) {
                
                if (i < iterations) {
                    new_title += original_title[i];
                } else {
                    if (original_title[i] === original_title[i].toUpperCase()) {
                        new_title += characters[Math.floor(Math.random() * characters.length)];
                    } else {
                        new_title += noCaps_characters[Math.floor(Math.random() * noCaps_characters.length)];
                    }
                }
            }

            setTitle(new_title);
            iterations += 1/3;

            if (iterations >= original_title.length) {
                clearInterval(interval);
            }
        }, 30);
    }

    return (
        <div className='section' id='contact'>
            
            <MailOutlineIcon className='section-header-icon contact-icon'/>

            <div className='section-content'>
                <Typography variant="h4" component="h4" gutterBottom className='text-heading'  onMouseOver={() => {handleMouseOver()}}>
                    {title}
                </Typography>

                <Typography variant="body1" gutterBottom>
                Welcome to the Contact section of my website! 
                Whether you have a question, an exciting project you'd like to collaborate on, or an opportunity for work, 
                I'm always open to hearing from you. Feel free to reach out to me via LinkedIn, Github, or email by clicking
                on the cards below. I look forward to hearing from you!
                </Typography>

                <br/><br/>
                
                <Box component='div' sx={[contact_card_container]}>
                    <Card className='contact-card' sx={[contact_card, {backgroundImage: 'url(' + image_1 + ')'}]}>
                        <CardActionArea href={linkedin} target="_blank" sx={[contact_card_action]}>
                            <Typography variant="h4" component="h4" gutterBottom className='text-heading'>
                                LinkedIn
                            </Typography>
                            <SvgIcon sx={[svg_scaleup(3), contact_card_icon]} component={LinkedIn} viewBox="0 5 50 50" />
                        </CardActionArea>
                    </Card>

                    <Card className='contact-card' sx={[contact_card, {backgroundImage: 'url(' + image_2 + ')'}]}>
                        <CardActionArea href={github} target="_blank" sx={[contact_card_action]}>
                            <Typography variant="h4" component="h4" gutterBottom className='text-heading'>
                                Github
                            </Typography>
                            <SvgIcon sx={[svg_scaleup(3), contact_card_icon]} component={Github} viewBox="0 5 60 60" />
                        </CardActionArea>
                    </Card>

                    <Card className='contact-card' sx={[contact_card, {backgroundImage: 'url(' + image_3 + ')'}]}>
                        <CardActionArea href={email} target="_blank" sx={[contact_card_action]}>
                            <Typography variant="h4" component="h4" gutterBottom className='text-heading'>
                                Email
                            </Typography>
                            <SvgIcon sx={[svg_scaleup(3), contact_card_icon]} component={Email} viewBox="0 5 50 50" />
                        </CardActionArea>
                    </Card>
                </Box>
            </div>
            
        </div>
    );
}