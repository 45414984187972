import React, {useState, useEffect} from 'react';
import { hexToRgb } from '../../scripts/tools.js';

var color_text = getComputedStyle(document.documentElement).getPropertyValue('--color-animation-text').replace('rgb(', '').replace(')', '');
var color_background = getComputedStyle(document.documentElement).getPropertyValue('--color-background-light').replace('rgb(', '').replace(')', '');

// In production, the lines above return the color in HEX format not RGB. We can check if the values start with a # and if so convert them to RGB
if (color_text.startsWith('#')) {
    color_text = hexToRgb(color_text);
    color_background = hexToRgb(color_background);
}

var i;
var j;
var line;

const asciiName = [
    "       __            _    ____                                        ",
    "      / /___ __   __(_)  / __ )____  ____ ____ ____  ____  _____ ___  ",
    " __  / / __ `/ | / / /  / __  / __ `/ ___/ ___/ __ `/ __ \\/ ___/ __ \\ ",
    "/ /_/ / /_/ /| |/ / /  / /_/ / /_/ / /  / /  / /_/ / / / / /__/ /_/ / ",
    "\\____/\\__,_/ |___/_/  /_____/\\__,_/_/  /_/   \\__,_/_/ /_/\\___/\\____/  "]

export default function AsciiAnimationSVG() {
    var [animHeight, setAnimHeight] = useState(0);
    var [animWidth, setAnimWidth] = useState(0);
    var [frame, setFrame] = useState([]);
    const numLines = 50
    const numChar = 100
    const probIniSpace = 0.99
    const probSpace = 0.01

    // At the beginning or when the window is resized, calculate the height and width of the animation-container div
    useEffect(() => {
        setAnimHeight(document.getElementById('animation-container').clientHeight);
        setAnimWidth(document.getElementById('animation-container').clientWidth);
    }, [window.innerWidth, window.innerHeight]);

    
    useEffect(() => {
        const intervalId = setInterval(() => {
            var updatedFrame = [];
            
            // If the frame is not the right size, generate a new frame with numLines lines and numChar characters per line with a probIniSpace probability of each character being a space
            if (frame.length < numLines || frame.length > numLines) {
                for (i = 0; i < numLines; i++) {
                    line = "";
                    for (j = 0; j < numChar; j++) {
                        if (Math.random() < probIniSpace) line += " ";
                        else line += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
                    }
                    updatedFrame.push(line);
                }
            } else { // Otherwise, update the frame
                for (i = 0; i < numLines; i++) {
                    line = "";
                    for (j = 0; j < numChar; j++) {
                        if (frame[i][j] === " ") {
                            if (Math.random() < probSpace) line += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
                            else line += " ";
                        } else {
                            if (Math.random() < probSpace) line += " ";
                            else line += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
                        }
                    }
                    updatedFrame.push(line);
                }
            }
            setFrame(updatedFrame);
        }, 100);
    
        return () => clearInterval(intervalId);
    }, [frame]);

    return (
        <div className='ascii-animation' id='animation-container'>
            <svg width="100%" height="100%" viewBox={'0 0 ' + animWidth + ' ' + animHeight}>
                
                {frame.map((line, index) => {
                    return (
                        <text 
                            x={0} 
                            y={animHeight / numLines * (index + 1) - 10}  
                            fill={'rgb(' + color_text + ')'} 
                            fontSize={animHeight / numLines} 
                            textLength={animWidth}
                            dominantBaseline="hanging"
                            fontFamily="monospace"
                            style={{whiteSpace: "pre"}}
                        >
                            {line}
                        </text>
                    );
                })}

                {asciiName.map((line, index) => {
                    if (index === 0) {
                        return (
                            <>
                                <text
                                x={animWidth / 2}
                                y={(animHeight / numLines * (numLines/2 - 3) - 10) + animHeight / numLines * (index + 1)}
                                fill='white'
                                fontSize={animHeight / numLines}
                                textAnchor="middle"
                                dominantBaseline="hanging"
                                fontFamily="monospace"
                                style={{whiteSpace: "pre"}}
                                >
                                    {line}
                                </text>
                            </>
                        );
                    } else {
                        return (
                            <>
                            <rect
                                x={animWidth / 2 - (line.length * (animHeight / numChar) * 1.21) / 2}
                                y={(animHeight / numLines * (numLines/2 - 3) - 10) + animHeight / numLines * (index + 1)}
                                width={line.length * (animHeight / numChar) * 1.18}
                                height={animHeight / numLines}
                                fill={'rgb(' + color_background + ')'}
                            />
                            <text
                                x={animWidth / 2}
                                y={(animHeight / numLines * (numLines/2 - 3) - 10) + animHeight / numLines * (index + 1)}
                                fill='white'
                                fontSize={animHeight / numLines}
                                textAnchor="middle"
                                dominantBaseline="hanging"
                                fontFamily="monospace"
                                style={{whiteSpace: "pre"}}
                            >
                                {line}
                            </text>
                            </>
                        );
                    }
                })}

            </svg>
        </div>
    );
}