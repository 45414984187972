// hexToRgb function to solve color inconsistencies between local and production builds

export function hexToRgb(hex) {
    // Turn Navy and Teal into their hex values
    if (hex === 'navy') {
        hex = '#000080';
    } else if (hex === 'teal') {
        hex = '#008080';
    }

    if (hex.length === 4) {
        hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    } 

    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    return r + ', ' + g + ', ' + b;
}

export function svg_scaleup(scale) {
    return (
        {
        transform: 'scale(' + scale + ')',
        marginRight: '5px',
    }
    )
}