import React from "react";

import Nav_v2 from "../Nav/Nav_v2";

import { Typography } from "@mui/material";

const title_style_1 = {
    color: '#fff',
    fontSize: '1.75em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0',
    textAlign: 'left',
}

const title_style_2 = {
    color: '#BDE038',
    fontWeight: 'bold',
    fontSize: '4.5em',
    textTransform: 'uppercase',
    letterSpacing: '0',
    textAlign: 'left',
}

export default function Header() {

    return (
        <>
            <div>
                <Typography variant="h2" className="hero-title" style={title_style_1}> Welcome, I'm </Typography>
                <Typography variant="h2" className="hero-title" style={title_style_2}> JAVI BARRANCO </Typography>
            </div>
            <Nav_v2 />
        </>
    );
}