import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

import ThreeGlobe from 'three-globe';
import { Text } from '@react-three/drei';

import { useLoader } from '@react-three/fiber';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Flow } from "three/examples/jsm/modifiers/CurveModifier.js";
import { Stats } from '@react-three/drei';

import countries from '../../static/json/custom.geo.json';
import moonTexture from '../../static/images/moon-map.jpeg';
import starsMap from '../../static/images/star-map.jpeg';
import cloudMap from '../../static/images/cloud-map.png';
import { randInt } from 'three/src/math/MathUtils';

extend({ ThreeGlobe });
extend({ TextGeometry });

const earthRotation = 0.005;
const cloudRotation = 0.006;

const canvas_style =  {
    zIndex: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    marginTop: '0%',
    borderRadius: '0px',
}

const Globe = (props) => {

    const globeRef = useRef();
    const cloudRef = useRef();

    useFrame(() => {
        // Rotate the Globe
        globeRef.current.rotation.y += earthRotation;
        globeRef.current.rotation.x += Math.sin(globeRef.current.rotation.y) * 0.0015;
    });

    useLayoutEffect(() => {
        // Create Globe Layer from internet images
        globeRef.current.globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
        globeRef.current.bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')

        // Custom Globe Material
        const globeMaterial = globeRef.current.globeMaterial();
        globeMaterial.bumpScale = 10;
        new THREE.TextureLoader().load('//unpkg.com/three-globe/example/img/earth-water.png', texture => {
            globeMaterial.specularMap = texture;
            globeMaterial.specular = new THREE.Color('grey');
            globeMaterial.shininess = 15;
        });

        // Rotate the Globe to show Spain
        globeRef.current.rotation.x = 0.2;
        globeRef.current.rotation.y = 0.05;

        // Adjust Globe Size
        globeRef.current.scale.x = 1;
        globeRef.current.scale.y = 1;
        globeRef.current.scale.z = 1;

        // Adjust Globe Position
        globeRef.current.position.x = 0;
        globeRef.current.position.y = 0;
        globeRef.current.position.z = 0;
    }, []);
    
    return (
        <threeGlobe {...props} ref={globeRef} />
    );
}

const GlobeClouds = (props) => {

    const cloudRef = useRef();

    useFrame(() => {
        // Rotate the Clouds
        cloudRef.current.rotation.y += cloudRotation;
        cloudRef.current.rotation.x += Math.sin(cloudRef.current.rotation.y) * cloudRotation * 0.1;
        cloudRef.current.rotation.z += Math.sin(cloudRef.current.rotation.y) * cloudRotation * 0.1;
    });

    useLayoutEffect(() => {
        // Cloud Mesh
        const cloudLoader = new THREE.TextureLoader();
        const cloudTexture = cloudLoader.load(cloudMap);
        const cloudGeometry = new THREE.SphereGeometry(101, 32, 32);
        const cloudMaterial = new THREE.MeshPhongMaterial({
            map: cloudTexture,
            transparent: true,
            opacity: 0.75,
        });

        cloudRef.current.geometry = cloudGeometry;
        cloudRef.current.material = cloudMaterial;
    }, []);

    return (
        <mesh {...props} ref={cloudRef} />
    );
}

const HexGlobe = (props) => {

    const hexRef = useRef();

    useFrame(() => {
        // Rotate the Globe
        hexRef.current.rotation.y += 0.015;
        hexRef.current.rotation.x += Math.sin(hexRef.current.rotation.y) * 0.0015;
    });

    useLayoutEffect(() => {
        // Create Hex Polygon Layer
        hexRef.current.hexPolygonsData(countries.features);
        hexRef.current.hexPolygonResolution(3);
        hexRef.current.hexPolygonMargin(0.7);
        hexRef.current.showAtmosphere(true);
        hexRef.current.atmosphereColor(0x3a228a);
        hexRef.current.atmosphereAltitude(0.25);
        

        // Adjust Globe Material
        var globeMaterial = hexRef.current.globeMaterial();
        globeMaterial.color = new THREE.Color(0x3a228a);
        globeMaterial.emissive = new THREE.Color(0x220038);
        globeMaterial.emissiveIntensity = 0.1;
        globeMaterial.shininess = 0.7;

        // Rotate the Globe to show Spain
        hexRef.current.rotation.x = 0.2;
        hexRef.current.rotation.y = 0.05;

        // Adjust Globe Size
        hexRef.current.scale.x = 1;
        hexRef.current.scale.y = 1;
        hexRef.current.scale.z = 1;

        // Adjust Globe Position
        hexRef.current.position.x = 100;
        hexRef.current.position.y = 0;
        hexRef.current.position.z = 0;
    }, []);

    return (
        <threeGlobe {...props} ref={hexRef} />
    );
}


const Lights = () => {
    return (
        <>
            <ambientLight color={0xbbbbbb} intensity={0.3} />
            <directionalLight color={0xffffff} intensity={0.8} position={[-800, 2000, 400]} />
            <directionalLight color={0x7982f6} intensity={1} position={[-200, 500, 200]} />
            <directionalLight color={0x8566cc} intensity={0.5} position={[-200, 500, 200]} />
            <spotLight position={[10, 15, 10]} angle={0.15} penumbra={1} />
            <pointLight position={[-10, -15, -10]} />
        </>
    );
}

// Moon Component
const Moon = (props) => {

    const moonRef = useRef();

    useFrame(() => {
        moonRef.current.rotation.y -= 0.005;

        // Make the Moon rotate around the Earth
        moonRef.current.position.z = Math.cos(Date.now() / 2500) * 230;
        moonRef.current.position.x = Math.sin(Date.now() / 2500) * 230;

        // Make it oscillate slightly on the y axis
        moonRef.current.position.y = Math.sin(Date.now() / 1000) * 10;
    });

    useLayoutEffect(() => {
        // Get Moon Layer
        moonRef.current.globeImageUrl(moonTexture);

        // Adjust Moon Material
        var globeMaterial = moonRef.current.globeMaterial();
        globeMaterial.color = new THREE.Color(0x3a228a);
        globeMaterial.emissive = new THREE.Color(0x220038);
        globeMaterial.emissiveIntensity = 0.25;
        globeMaterial.shininess = 1;

        // Adjust Moon Size
        const moonEarthRatio = 0.2724;
        moonRef.current.scale.x = moonEarthRatio;
        moonRef.current.scale.y = moonEarthRatio;
        moonRef.current.scale.z = moonEarthRatio;

        // Adjust Moon Position
        moonRef.current.position.x = 0;
        moonRef.current.position.y = 0;
        moonRef.current.position.z = 0;
    }, []);
    
    return (
        <threeGlobe {...props} ref={moonRef} />
    );
}

const OrbitingText = (props) => {

    const { disp, text, fontSize, lookAt } = props;

    const fontJSON = 'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/fonts/helvetiker_regular.typeface.json';
    const textRef = useRef();
    const font = useLoader(FontLoader, fontJSON);

    useFrame(() => {
        // Make text orbit around the globe
        const x = Math.sin(Date.now() / 1000 + (Math.PI/2 * disp)) * 100;
        const z = Math.cos(Date.now() / 1000 + (Math.PI/2 * disp)) * 100;
        textRef.current.position.set(x, 0, z);

        // Make the text's back face the planet
        textRef.current.lookAt(...lookAt);
        textRef.current.rotation.y += Math.PI;
    });        

    return (
        <Text ref={textRef} font={font} fontSize={fontSize} color="#ffffff" position={[0, 0, 0]}>
            {text}
        </Text>
    );
}

const OrbitingText3D = (props) => {

    const { disp, text, fontSize, lookAt } = props;

    const meshRef = useRef();

    // Load an appropriate font for 3D text
    const font3D = useLoader(FontLoader, 'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/fonts/helvetiker_regular.typeface.json');

    useFrame(() => {
        // Center the object by making its center point the origin
        meshRef.current.geometry.center(); 

        // Make the Mesh rotate around the globe
        meshRef.current.position.x =  Math.sin(Date.now() / 1000 + (Math.PI/2 * disp)) * 100;
        meshRef.current.position.z =  Math.cos(Date.now() / 1000 + (Math.PI/2 * disp)) * 100;

        // Make the text's back face the planet
        meshRef.current.lookAt(...lookAt);
        meshRef.current.rotation.y += Math.PI;
    });

    return (
        <mesh ref={meshRef}>
            <textGeometry attach="geometry" args={[text, { font: font3D, size: fontSize, height: 3}]} />
            <meshPhysicalMaterial attach="material" color="#ffffff" />
        </mesh>
    );
}

const StarsBackground = () => {

    const starsRef = useRef();
    const numStars = 5000;
    var rho, theta, phi, star_x, star_y, star_z;
    var star_red, star_green, star_blue;
    var star_size;

    // Rotate the stars around the origin
    useFrame(() => {
        starsRef.current.rotation.y += 0.001;
    });

    const stars = [];
    for (let i = 0; i < numStars; i++) {
        rho = 500 + (500 * Math.random());
        theta = 2 * Math.PI * Math.random();
        phi = 2 * Math.PI * Math.random();
        star_x = rho * Math.sin(phi) * Math.cos(theta);
        star_z = rho * Math.sin(phi) * Math.sin(theta);
        star_y = rho * Math.cos(phi);
        // We generate a random color (making sure it's not too dark)
        star_red = Math.floor(Math.random() * 100) + 155;
        star_green = Math.floor(Math.random() * 100) + 155;
        star_blue = Math.floor(Math.random() * 100) + 155;
        stars.push(
            <mesh key={i} position={[star_x, star_y, star_z]} >
                <sphereBufferGeometry attach="geometry" args={[1, 3, 2]} />
                <meshBasicMaterial attach="material" color={`rgb(${star_red}, ${star_green}, ${star_blue})`} />
            </mesh>
        );
    }

    return (
        <group ref={starsRef}>
            {stars}
        </group>
    );
}

const StarsBackgroundMap = (props) => {

    const starsRef = useRef();

    useFrame(() => {
        starsRef.current.rotation.y += 0.0005;
        starsRef.current.rotation.x = Math.sin(Date.now() / 1000) * 0.001;
    });

    useLayoutEffect(() => {
        const geometry = new THREE.SphereGeometry(300, 250, 250);

        const loader = new THREE.TextureLoader();
        const texture = loader.load(starsMap);

        // Resize the texture to make it smalles so it fits better
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set( 1, 1 );

        const material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.BackSide,
        });

        // Lower the opacity of the mesh
        material.opacity = 0.25;
        material.transparent = true;

        const mesh = new THREE.Mesh(geometry, material);
        
        starsRef.current.add(mesh);
    }, []);

    return (
        <mesh ref={starsRef} position={[0, 0, 0]} />
    );
}

export default function Scene() {

    const globeCenter = [125, 0, 0]

    return (
        <Canvas style={canvas_style} camera={{ fov: 75, position: [0, 0, 230] }} 
            onCreated={({ gl }) => {
                gl.alpha = true; // enable alpha
                gl.setClearColor(new THREE.Color("transparent"), 0); // set clear color to transparent and clear alpha to 0
            }}
        >
            <fog attach="fog" args={['#0x535ef3', 400, 2000]} />
            <Lights />
            {/*
            <StarsBackgroundMap />
            <Moon props={{ waitForGlobeReady: true, animateIn: true }}/>
            <Stats />
            */}
            <group position={globeCenter}>
                <Globe props={{ waitForGlobeReady: true, animateIn: true }}/>
                <GlobeClouds props={{ waitForGlobeReady: true, animateIn: true }}/>
                {
                    /*
                <OrbitingText3D disp={0} text={"JAVI BARRANCO"} fontSize={10} lookAt={globeCenter}/>
                <OrbitingText3D disp={2} text={"JAVI BARRANCO"} fontSize={10} lookAt={globeCenter}/>
                <OrbitingText3D disp={1} text={"WORK IN PROGRESS"} fontSize={8} lookAt={globeCenter}/>
                <OrbitingText3D disp={3} text={"WORK IN PROGRESS"} fontSize={8} lookAt={globeCenter}/>
                    */
                }
            </group>
        </Canvas>
    );
}