import './styles/App.css';
import './styles/general.css';

import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import Contact from './components/Contact/Contact';
import Nav from './components/Nav/Nav';
import AsciiAnimationSVG from './components/AsciiAnimation/AsciiAnimationSVG';
import Scene from './components/ThreeScene/Scene';
import HomeButton from './components/Nav/HomeButton';

import { createTheme } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Space Mono',
            'Roboto',
            'sans-serif',
        ].join(','),
    },
});

export default function App() {

    window.onscroll = function() {
        let currentScrollPos = window.pageYOffset;
        let homeBottom = document.getElementById('home').offsetHeight;

        if (currentScrollPos > homeBottom - 15) {
            document.getElementById('home-button-container').classList.add('home-button-container-show');   
        } else {
            document.getElementById('home-button-container').classList.remove('home-button-container-show');
        }
    }
                                                                
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <div className='landing-view' id='home'>
                    <div className='hero-actions'>
                        <Header/>
                    </div>

                    <div className='hero-container'>
                        <Scene/>
                    </div>
                </div>

                <HomeButton/>

                <div className='sections-container'>
                    <About/>
                    <Projects/>
                    <Skills/>
                    <Contact/>
                </div>
            </div>
        </ThemeProvider>
    );
}