import React, { useState } from "react";
import '../../styles/nav.css';
import { hexToRgb } from '../../scripts/tools.js';

import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import PersonIcon from '@mui/icons-material/Person';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandymanIcon from '@mui/icons-material/Handyman';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

var about_color = getComputedStyle(document.documentElement).getPropertyValue('--about-icon-color').replace('rgb(', '').replace(')', '');
var about_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--about-icon-color-darker').replace('rgb(', '').replace(')', '');
var projects_color = getComputedStyle(document.documentElement).getPropertyValue('--projects-icon-color').replace('rgb(', '').replace(')', '');
var projects_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--projects-icon-color-darker').replace('rgb(', '').replace(')', '');
var skills_color = getComputedStyle(document.documentElement).getPropertyValue('--skills-icon-color').replace('rgb(', '').replace(')', '');
var skills_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--skills-icon-color-darker').replace('rgb(', '').replace(')', '');
var contact_color = getComputedStyle(document.documentElement).getPropertyValue('--contact-icon-color').replace('rgb(', '').replace(')', '');
var contact_color_darker = getComputedStyle(document.documentElement).getPropertyValue('--contact-icon-color-darker').replace('rgb(', '').replace(')', '');

// In production, the lines above return the color in HEX format not RGB. We can check if the values start with a # and if so convert them to RGB
if (about_color.startsWith('#')) {
    about_color = hexToRgb(about_color);
    about_color_darker = hexToRgb(about_color_darker);
    projects_color = hexToRgb(projects_color);
    projects_color_darker = hexToRgb(projects_color_darker);
    skills_color = hexToRgb(skills_color);
    skills_color_darker = hexToRgb(skills_color_darker);
    contact_color = hexToRgb(contact_color);
    contact_color_darker = hexToRgb(contact_color_darker);
}

const button_container = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: '2rem',
    width: '100%',        
}

const button_style = {
    borderRadius: '5px',
    justifyContent: 'flex-start',
    color: '#fff',
    textWeight: 'bold',
    fontSize: '3rem',

    // Remove the extra space between the text and the button border
    paddingTop: 0,
    paddingBottom: 0,


    // Make the width slightly longer than the text
    width: 'fit-content',
    paddingRight: '1.5rem',

    // We 'cut out' the bottom right corner of the button to make it look like a triangle
    clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%)',

    marginTop: '0.75rem',

    '&:hover': {
        cursor: 'pointer',
        color: '#000',
        backgroundColor: 'rgba(255, 255, 255, 1)'
    }
}

const first_button_style = {
    borderRadius: '5px',
    justifyContent: 'flex-start',
    color: '#000',
    textWeight: 'bold',
    fontSize: '3rem',
    paddingTop: 0,
    paddingBottom: 0,
    width: 'fit-content',
    paddingRight: '1.5rem',
    clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%)',
    marginTop: '0.75rem',

    // The First Button always stays open to the color of color_highlight
    backgroundColor: '#BDE038',

    '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#BDE038',
    }
}

const about = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + about_color + ', 0.2)',

    '&:hover': {
        backgroundColor: 'rgba(' + about_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + about_color + ', 0.5)',
    }
}

const projects = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + projects_color + ', 0.2)',
    '&:hover': {
        backgroundColor: 'rgba(' + projects_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + projects_color + ', 0.5)',
    }
}

const skills = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + skills_color + ', 0.2)',
    '&:hover': {
        backgroundColor: 'rgba(' + skills_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + skills_color + ', 0.5)',
    }
}

const contact = {
    color: '#fff',
    textWeight: 'bold',
    backgroundColor: 'rgba(' + contact_color + ', 0.2)',
    '&:hover': {
        backgroundColor: 'rgba(' + contact_color_darker + ', 0.75)',
        boxShadow: '0px 0px 10px 3px rgba(' + contact_color + ', 0.5)',
    }
}

const theme_glitch = createTheme()

theme_glitch.typography.h6 = {
    fontSize: '0.75rem',
    color: '#fff',
}

export default function Nav_v2() {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%&!?';
    const numbers = '0123456789';

    const original_text = ['HOME', 'ABOUT', 'PROJECTS', 'SKILLS', 'CONTACT']
    const original_text_glitch = ['PAGE/001', 'PAGE/002', 'PAGE/003', 'PAGE/004', 'PAGE/005']

    const [home_text, setHomeText] = useState('HOME');
    const [home_bool_glitch, setHomeBoolGlitch] = useState(true);
    const [home_text_glitch, setHomeTextGlitch] = useState('PAGE/001');
    const [about_text, setAboutText] = useState('ABOUT');
    const [about_bool_glitch, setAboutBoolGlitch] = useState(false);
    const [about_text_glitch, setAboutTextGlitch] = useState('PAGE/002');
    const [projects_text, setProjectsText] = useState('PROJECTS');
    const [projects_bool_glitch, setProjectsBoolGlitch] = useState(false);
    const [projects_text_glitch, setProjectsTextGlitch] = useState('PAGE/003');
    const [skills_text, setSkillsText] = useState('SKILLS');
    const [skills_bool_glitch, setSkillsBoolGlitch] = useState(false);
    const [skills_text_glitch, setSkillsTextGlitch] = useState('PAGE/004');
    const [contact_text, setContactText] = useState('CONTACT');
    const [contact_bool_glitch, setContactBoolGlitch] = useState(false);
    const [contact_text_glitch, setContactTextGlitch] = useState('PAGE/005');

    // Text Glitching Effect onMouseOver
    const handleMouseOver = (current_text, setText, original_text, setBool, text_glitch, setTextGlitch, original_text_glitch) => {
        let iterations = 0;

        setBool(true);

        const interval = setInterval(() => {
            let new_text = '';
            
            for (let i = 0; i < original_text.length; i++) {

                if (i < iterations) {
                    new_text += original_text[i];
                    continue;
                }

                new_text += characters[Math.floor(Math.random() * characters.length)];
            }
            
            setText(new_text);

            iterations += 1 / 3;
            
            if (iterations > original_text.length) {
                clearInterval(interval);
                setText(original_text);
            }

        }, 30);

        let iterations_glitch = -1;

        const interval_glitch = setInterval(() => {
            let new_page_text = '';
            let new_number_text = '';

            for (let i = 0; i < original_text_glitch.split('/')[0].length; i++) {
                if (i < iterations_glitch) {
                    new_page_text += original_text_glitch.split('/')[0][i];
                    continue;
                }

                new_page_text += characters[Math.floor(Math.random() * characters.length)];
            }

            for (let i = 0; i < original_text_glitch.split('/')[1].length; i++) {
                if (i < iterations_glitch) {
                    new_number_text += original_text_glitch.split('/')[1][i];
                    continue;
                }

                new_number_text += numbers[Math.floor(Math.random() * numbers.length)];
            }

            setTextGlitch(new_page_text + '/' + new_number_text);

            iterations_glitch += 1 / 4;

            if (iterations_glitch > original_text_glitch.length) {
                clearInterval(interval_glitch);
                setTextGlitch(original_text_glitch);
            }

        }, 30);
    }

    return (
        <div style={button_container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button className='button-text' sx={[first_button_style]} onClick = {() => {scrollToHome();scrollToAbout()}} onMouseOver={() => handleMouseOver(home_text, setHomeText, original_text[0], setHomeBoolGlitch, home_text_glitch, setHomeTextGlitch, original_text_glitch[0])} onMouseLeave={() => {setHomeBoolGlitch(true)}}>
                    <Typography variant="h3"> {home_text} </Typography>
                </Button>
               {
                    home_bool_glitch ?
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px',  paddingLeft: '10px'}}>
                        <ThemeProvider theme={theme_glitch}>
                            <Typography variant="h6" style={{ color: '#BDE038' }}> {home_text_glitch.split('/')[0]} </Typography>
                            <Typography variant="h6" style={{ alignSelf: 'flex-start', marginTop: '-6px',  color: '#BDE038' }}> {home_text_glitch.split('/')[1]} </Typography>
                        </ThemeProvider>
                    </div>
                    :
                    <></>
               }
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button className='button-text' sx={[button_style]} onClick = {() => {scrollToAbout();}} onMouseOver={() => handleMouseOver(about_text, setAboutText, original_text[1], setAboutBoolGlitch, about_text_glitch, setAboutTextGlitch, original_text_glitch[1])} onMouseLeave={() => {setAboutBoolGlitch(false)}}>
                    <Typography variant="h3"> {about_text} </Typography>
                </Button>
                {
                    about_bool_glitch ?
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px',  paddingLeft: '10px'}}>
                        <ThemeProvider theme={theme_glitch}>
                            <Typography variant="h6"> {about_text_glitch.split('/')[0]} </Typography>
                            <Typography variant="h6" style={{ alignSelf: 'flex-start', marginTop: '-6px'}}> {about_text_glitch.split('/')[1]} </Typography>
                        </ThemeProvider>
                    </div>
                    :
                    <></>
                }
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button className='button-text' sx={[button_style]} onClick = {() => {scrollToProjects();}} onMouseOver={() => handleMouseOver(projects_text, setProjectsText, original_text[2], setProjectsBoolGlitch, projects_text_glitch, setProjectsTextGlitch, original_text_glitch[2])} onMouseLeave={() => {setProjectsBoolGlitch(false)}}>
                    <Typography variant="h3"> {projects_text} </Typography>
                </Button>
                {
                    projects_bool_glitch ?
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px',  paddingLeft: '10px'}}>
                        <ThemeProvider theme={theme_glitch}>
                            <Typography variant="h6"> {projects_text_glitch.split('/')[0]} </Typography>
                            <Typography variant="h6" style={{ alignSelf: 'flex-start', marginTop: '-6px'}}> {projects_text_glitch.split('/')[1]} </Typography>
                        </ThemeProvider>
                    </div>
                    :
                    <></>
                }
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button className='button-text' sx={[button_style]} onClick = {() => {scrollToSkills();}} onMouseOver={() => handleMouseOver(skills_text, setSkillsText, original_text[3], setSkillsBoolGlitch, skills_text_glitch, setSkillsTextGlitch, original_text_glitch[3])} onMouseLeave={() => {setSkillsBoolGlitch(false)}}>
                    <Typography variant="h3"> {skills_text} </Typography>
                </Button>
                {
                    skills_bool_glitch ?
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px',  paddingLeft: '10px'}}>
                        <ThemeProvider theme={theme_glitch}>
                            <Typography variant="h6"> {skills_text_glitch.split('/')[0]} </Typography>
                            <Typography variant="h6" style={{ alignSelf: 'flex-start', marginTop: '-6px'}}> {skills_text_glitch.split('/')[1]} </Typography>
                        </ThemeProvider>
                    </div>
                    :
                    <></>
                }
            </div>


            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button className='button-text' sx={[button_style]} onClick = {() => {scrollToContact();}} onMouseOver={() => handleMouseOver(contact_text, setContactText, original_text[4], setContactBoolGlitch, contact_text_glitch, setContactTextGlitch, original_text_glitch[4])} onMouseLeave={() => {setContactBoolGlitch(false)}}>
                    <Typography variant="h3"> {contact_text} </Typography>
                </Button>
                {
                    contact_bool_glitch ?
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px',  paddingLeft: '10px'}}>
                        <ThemeProvider theme={theme_glitch}>
                            <Typography variant="h6"> {contact_text_glitch.split('/')[0]} </Typography>
                            <Typography variant="h6" style={{ alignSelf: 'flex-start', marginTop: '-6px'}}> {contact_text_glitch.split('/')[1]} </Typography>
                        </ThemeProvider>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    );

    function scrollToHome() {
        // Scroll to the home section
        var about = document.getElementById('home');
        about.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    function scrollToAbout() {
        // Scroll to the about section
        var about = document.getElementById('about');
        about.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    function scrollToProjects() {
        // Scroll to the projects section
        var projects = document.getElementById('projects');
        projects.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    function scrollToSkills() {
        // Scroll to the skills section
        var skills = document.getElementById('skills');
        skills.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    function scrollToContact() {
        // Scroll to the contact section
        var contact = document.getElementById('contact');
        contact.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
}