import React from "react";
import "../../styles/break.css";

import { Typography } from "@mui/material";

export default function Break() {
    return (
        <div className="break">
            <Typography variant="body1" className="break-text">
                ========================================================================================================================================================================================================================================================================================================================================================================================================================================================================
            </Typography>
        </div>
    );
}