// Imports of React and my own code
import React, { useState, useEffect } from 'react';
import '../../styles/sections.css';
import { hexToRgb, svg_scaleup } from '../../scripts/tools.js';
import Break from '../Break/Break';

// Imports of Material UI components
import { Typography } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Backdrop from '@mui/material/Backdrop';
import { AnimatePresence, motion } from 'framer-motion';

// Import of Icons
import { ReactComponent as Python48 } from '../../static/icons/icons8-python-color/icons8-python-48.svg';
import { ReactComponent as Pandas48 } from '../../static/icons/icons8-pandas-color/icons8-pandas-48.svg';
import { ReactComponent as Medal } from '../../static/icons/icons8-medal-color/icons8-medal.svg';
import { ReactComponent as ReactLogo } from '../../static/icons/icons8-react-color/icons8-react-80.svg';
import { ReactComponent as JavaScript48 } from '../../static/icons/icons8-javascript-color/icons8-javascript-48.svg';
import { ReactComponent as CSS3 } from '../../static/icons/icons8-css3-color/icons8-css3-48.svg';
import { ReactComponent as HTML5 } from '../../static/icons/icons8-html5-color/icons8-html-5-48.svg';
import { ReactComponent as PostgreSQL } from '../../static/icons/icons8-postgresql-color/icons8-postgresql-48.svg';
import { ReactComponent as News} from '../../static/icons/icons8-news-color/icons8-news-128.svg';
import { ReactComponent as GitHub } from '../../static/icons/icons8-github-color/icons8-github-64.svg';
import { ReactComponent as LinkedIn } from '../../static/icons/icons8-linkedin-color/icons8-linkedin-48.svg';
import { ReactComponent as Link } from '../../static/icons/icons8-links-color/link.svg';
import { ReactComponent as MaterialUI } from '../../static/icons/icons8-material-ui-color/icons8-material-ui-48.svg';
import { ReactComponent as ThreeJS } from '../../static/icons/threejs-logo/Threejs-logo.svg';

// Import of JSON files
import project_list from '../../static/json/projects.json';

// Import Colors
var card_background = getComputedStyle(document.documentElement).getPropertyValue('--color-background-light').replace('rgb(', '').replace(')', '');
var subcard_background = getComputedStyle(document.documentElement).getPropertyValue('--color-background-light-v2').replace('rgb(', '').replace(')', '');
var card_text_color_primary = getComputedStyle(document.documentElement).getPropertyValue('--color-heading').replace('rgb(', '').replace(')', '');
var card_text_color_secondary = getComputedStyle(document.documentElement).getPropertyValue('--color-text').replace('rgb(', '').replace(')', '');
var card_shadow_color = getComputedStyle(document.documentElement).getPropertyValue('--color-animation-text').replace('rgb(', '').replace(')', '');
var card_hover_text_color = getComputedStyle(document.documentElement).getPropertyValue('--color-animation-text-light').replace('rgb(', '').replace(')', '');

// In production, the lines above return the color in HEX format not RGB. We can check if the values start with a # and if so convert them to RGB
if (card_background.startsWith('#')) {
    card_background = hexToRgb(card_background);
    subcard_background = hexToRgb(subcard_background);
    card_text_color_primary = hexToRgb(card_text_color_primary);
    card_text_color_secondary = hexToRgb(card_text_color_secondary);
    card_shadow_color = hexToRgb(card_shadow_color);
    card_hover_text_color = hexToRgb(card_hover_text_color);
}

// Styles
const card_container_style = {
    width: '99%',
    alignSelf: 'center',
}

const card_style = {
    backgroundColor: 'rgb(' + card_background + ')',
    height: '100%',

    '&:hover': {
        '& .MuiTypography-root.MuiTypography-h5': {
            color: 'rgb(' + card_hover_text_color + ')',
            transition: 'color 0.25s ease',
        },
        transform: 'scale(1.01)',
        boxShadow: '0 0 10px 5px rgba(' + card_shadow_color + ', 0.75)',
        transition: 'box-shadow 0.25s ease',
    }
}

const card_action_style = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}

const card_content_style = {
    margin: 'auto',
    marginTop: '0px',
    marginLeft: '0px',
    // We are adding a small margin with auto so we need to account for it when calculating the width
    width: 'calc(100% - 30px)',
}

const actions_style = {
    marginTop: 'auto',
    alignSelf: 'flex-start',  
}

const card_text_primary = {
    color: 'rgb(' + card_text_color_primary + ')',
}

const card_text_secondary = {
    color: 'rgb(' + card_text_color_secondary + ')',
}

const learn_more_button = {
    marginRight: '15px',
}

const card_separator = {
    color: 'rgba(' + card_text_color_secondary + ', 0.15)',
    overflow: 'hidden',
    textOverflow: "clip",
}

const backdrop_style = { 
    zIndex: 9999, 
    backgroundColor: 'rgba(0,0,0,0.50)', 
    backdropFilter: 'blur(5px)',
}

const card_style_expanded = {
    backgroundColor: 'rgb(' + card_background + ')',
    width: '60%',
    height: '75%',
    overflowY: 'auto',

    '&:hover': {
        boxShadow: '0 0 10px 5px rgba(' + card_shadow_color + ', 0.75)',
        transition: 'box-shadow 0.5s ease',
    },
}

const link_cards_extended = {
    zIndex: '10000', 
    width: '75%',
    backgroundColor: 'rgb(' + subcard_background + ')',
    boxShadow: '0 0 5px 3px rgba(' + card_shadow_color + ', 0.10)',

    '&:hover': {
        boxShadow: '0 0 8px 3px rgba(' + card_shadow_color + ', 0.75)',
        transition: 'box-shadow 0.25s ease',
    },
}

const link_cards_action_extended = {
    zIndex: '10000',
    display: 'flex',
    flexDirection: 'row',

    height: '35px',
    width: '100%',
}

// Projects Component
export default function Projects() {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$%&!?';
    const noCaps_characters = 'abcdefghijklmnopqrstuvwxyz@#$%&!?';

    const original_title = 'Projects';

    const [title, setTitle] = useState(original_title);

    const [selectedId, setSelectedId] = useState(null);
    const [hoveringSubCard, setHoveringSubCard] = useState(false);

    // Disable scrolling in body when selectedId is not null
    useEffect(() => {
        if (selectedId !== null) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [selectedId]);

    const handleMouseOver = () => {
        let iterations = 0;

        const interval = setInterval(() => {
            let new_title = '';

            for (let i = 0; i < original_title.length; i++) {
                
                if (i < iterations) {
                    new_title += original_title[i];
                } else {
                    if (original_title[i] === original_title[i].toUpperCase()) {
                        new_title += characters[Math.floor(Math.random() * characters.length)];
                    } else {
                        new_title += noCaps_characters[Math.floor(Math.random() * noCaps_characters.length)];
                    }
                }
            }

            setTitle(new_title);
            iterations += 1/2;

            if (iterations >= original_title.length) {
                clearInterval(interval);
            }
        }, 30);
    }

    return (
        <div className='section' id='projects' >
            
            <RocketLaunchIcon className='section-header-icon projects-icon'/>

            <div className='section-content'>
                <Typography variant="h4" component="h4" gutterBottom className='text-heading' onMouseOver={() => {handleMouseOver()}}>
                    {title}
                </Typography>

                <Typography variant="body1" gutterBottom>
                Welcome to my projects! Here, you will find a collection of personal projects, hackathons, and other coding challenges that I have worked on. 
                These projects represent a diverse range of interests, from software development to data analysis and machine learning.
                </Typography>

                <br/><br/>

                <Grid container spacing={2} sx={[card_container_style]}>
                    {project_list.map((project) => {
                        if (project["show"] === false) return <></>;
                        
                        return (
                        <Grid item xs={12} sm={6} md={6}>
                            <motion.div 
                                layoutId={project['id']} 
                                style={{ height: '100%'}}
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <Card className='project-card' sx={[card_style]}>
                                    <CardActionArea onClick={() => {setSelectedId(null); setSelectedId(project['id'])}} sx={[card_action_style]}>
                                        <CardMedia component="img" height="140" image={require('../../static/images/' + project['image'])} title={project['title']}/>
                                        <CardContent sx={[card_content_style]}>
                                            <Typography sx={[card_text_primary]} gutterBottom variant="h5" component="div">
                                                {project['title']}
                                            </Typography>
                                            <Typography sx={[card_text_secondary]} variant="body1" color="text.secondary">
                                                {project['date']}
                                            </Typography>
                                            <Typography sx={[card_text_secondary, card_separator]} variant="body1" color="text.secondary" noWrap='True'>
                                                ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                            </Typography>
                                            <Typography sx={[card_text_secondary]} variant="body2" color="text.secondary">
                                                {project['description']}
                                            </Typography>
                                        </CardContent>
                                        <CardActions sx={[actions_style]}>
                                            <Button onClick={() => {setSelectedId(null); setSelectedId(project['id'])}} sx={[learn_more_button]} size="small">Learn More</Button>
                                            {project['badges'].map((badge) => {
                                                return(
                                                    getBadge(badge)
                                                )
                                            })}
                                        </CardActions>
                                    </CardActionArea>
                                </Card>
                            </motion.div>
                        </Grid>
                        )
                    })}
                </Grid>
                
                <AnimatePresence>
                    {selectedId && (
                        <motion.div 
                            layoutId={selectedId}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.5 } }}
                            exit={{ opacity: 0 }}
                        >
                            {project_list.map((project) => {
                                if (project["id"] !== selectedId) return <></>;
                                
                                return (
                                    <Backdrop open={true} sx={ backdrop_style } onClick={() => {handleClickBackdrop()}}>
                                        <Card className='project-card' sx={[card_style_expanded]}>
                                            <CardMedia component="img" height="140" image={require('../../static/images/' + project['image'])} title={project['title']}/>
                                            <CardContent sx={[]}>
                                                <Typography sx={[card_text_primary]} gutterBottom variant="h5" component="div">
                                                    {project['title']}
                                                </Typography>
                                                <Typography sx={[card_text_secondary]} variant="body1" color="text.secondary">
                                                    {project['date']}
                                                </Typography>
                                                <Typography sx={[card_text_secondary, card_separator]} variant="body1" color="text.secondary" noWrap='True'>
                                                    ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                                </Typography>
                                                <Typography sx={[card_text_primary]} variant="h6" color="text.secondary">
                                                    Description
                                                </Typography>
                                                {project['expanded_description'].split('\n').map((item, key) => {
                                                    return (
                                                        <>
                                                        <Typography sx={[card_text_secondary]} variant="body2" color="text.secondary">
                                                            {item}
                                                        </Typography>
                                                        <br/>
                                                        </>
                                                    )
                                                })}
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
                                                        <Typography sx={[card_text_primary]} variant="h6" color="text.secondary">
                                                            Links
                                                        </Typography>
                                                        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                                                            {project['links'].map((link) => {
                                                                return(
                                                                    <li style={{ marginBottom: '10px' }}>

                                                                        <Card sx={[link_cards_extended]} onMouseEnter={() => {setHoveringSubCard(true)}} onMouseLeave={() => {setHoveringSubCard(false)}}>
                                                                            <CardActionArea onClick={() => window.open(link.url, '_blank')} sx={[link_cards_action_extended]}>
                                                                                {getBadge(link.icon)}
                                                                                <Typography sx={[card_text_primary, { marginLeft: '15px' }]} variant="body2">
                                                                                    {link.name}
                                                                                </Typography>
                                                                            </CardActionArea>
                                                                        </Card>

                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
                                                        <Typography sx={[card_text_primary]} variant="h6" color="text.secondary">
                                                            Badges
                                                        </Typography>
                                                        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}> 
                                                            {project['badges'].map((badge) => {
                                                                return(
                                                                    <li style={{ marginBottom: '15px' }}>
                                                                        {getBadge(badge, true)}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Backdrop>
                                )
                            })}
                        </motion.div>
                    )}
                </AnimatePresence>

                <Break/>
            </div>
            
        </div>
    );

    function getBadge(badge, desc=false) {
        var icon = null;
        var description = null;

        if (badge === 'Python') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={Python48} viewBox="0 0 48 48"/>;
            description = 'Powered by Python';
        } else if (badge === 'Pandas') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={Pandas48} viewBox="0 0 48 48"/>;
            description = 'Powered by Pandas';
        } else if (badge === 'JavaScript') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={JavaScript48} viewBox="0 0 48 48"/>;
            description = 'Powered by JavaScript';
        } else if (badge === 'CSS') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={CSS3} viewBox="0 0 48 48"/>;
            description = 'Powered by CSS';
        } else if (badge === 'React') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={ReactLogo} viewBox="0 -10 96 96"/>;
            description = 'Powered by React';
        } else if (badge === 'Medal') {
            icon = <SvgIcon sx={[svg_scaleup(2.25)]} component={Medal} viewBox="-15 -15 96 96"/>;
            description = 'Award Winning Project';
        } else if (badge === 'HTML') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={HTML5} viewBox="0 0 48 48"/>;
            description = 'Powered by HTML';
        } else if (badge === 'psql') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={PostgreSQL} viewBox="0 0 48 48"/>;
            description = 'Powered by PostgreSQL';
        } else if (badge === 'news') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={News} viewBox="0 0 128 128"/>;
            description = 'Link to News Article about Project';
        } else if (badge === 'link') {
            icon = <SvgIcon sx={[svg_scaleup(1.3)]} component={Link} viewBox="0 0 280 280"/>;
            description = 'Link to external website';
        } else if (badge === 'github') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={GitHub} viewBox="0 0 64 64"/>;
            description = 'Project uploaded to Github';
        } else if (badge === 'linkedin') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={LinkedIn} viewBox="0 0 48 48"/>;
            description = 'Project shared on LinkedIn';
        } else if (badge === 'material-ui') {
            icon = <SvgIcon sx={[svg_scaleup(1.5)]} component={MaterialUI} viewBox="0 0 48 48"/>;
            description = 'Powered by Material-UI';
        } else if (badge === 'threejs') {
            icon = <SvgIcon sx={[svg_scaleup(1.2)]} component={ThreeJS} viewBox="0 0 650 650"/>;
            description = 'Powered by ThreeJS';
        } else {
            return <></>
        }

        if (desc) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {icon}
                    <Typography sx={[card_text_secondary, { marginLeft: '15px' }]} variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </div>
            );
        } else {
            return icon;
        }
    }

    function handleClickBackdrop() {
        if (hoveringSubCard) {
            return;
        } else {
            setSelectedId(null);
        }
    }
}