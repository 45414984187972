import React from 'react';
import '../../styles/nav.css';

import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const home_button_style = {
    position: 'sticky',
    margin: 'auto',
    top: '2rem',

    backgroundColor: 'rgba(26, 140, 239, 0.25)',
    backdropFilter: 'blur(5px)',
    boxShadow: '0 0px 15px 2px rgba(15, 81, 138, 0.25)',

    borderRadius: '50%',
    width: '30px',
    height: '60px',

    scale: '0.85',

    transition: '0.3s ease-in',

    '&:hover': {
        backgroundColor: 'rgba(26, 140, 239, 0.3)',
        boxShadow: '0 0px 50px 2px rgba(15, 81, 138, 0.45)',
        scale: '0.9',
        transition: '0.15s ease-in'
    },
}

export default function HomeButton() {

    return (
        <div className='home-btn-container' id='home-button-container'>
            <Button sx={[home_button_style]} onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}}>
                <KeyboardArrowUpIcon sx={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '2.5rem'}}/>
            </Button>
        </div>
    );
}