import React from 'react';
import '../../styles/sections.css';

import { Typography } from '@mui/material';
import HandymanIcon from '@mui/icons-material/Handyman';
import Grid from '@mui/material/Grid';
import Break from '../Break/Break';

export default function Skills() {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$%&!?';
    const noCaps_characters = 'abcdefghijklmnopqrstuvwxyz@#$%&!?';

    const original_title = 'Skills';

    const [title, setTitle] = React.useState(original_title);

    const handleMouseOver = () => {
        let iterations = 0;

        const interval = setInterval(() => {
            let new_title = '';

            for (let i = 0; i < original_title.length; i++) {
                
                if (i < iterations) {
                    new_title += original_title[i];
                } else {
                    if (original_title[i] === original_title[i].toUpperCase()) {
                        new_title += characters[Math.floor(Math.random() * characters.length)];
                    } else {
                        new_title += noCaps_characters[Math.floor(Math.random() * noCaps_characters.length)];
                    }
                }
            }

            setTitle(new_title);
            iterations += 1/3;

            if (iterations >= original_title.length) {
                clearInterval(interval);
            }
        }, 30);
    }

    return (
        <div className='section' id='skills'>
            
            <HandymanIcon className='section-header-icon skills-icon'/>

            <div className='section-content'>
                <Typography variant="h4" component="h4" gutterBottom className='text-heading' onMouseOver={() => {handleMouseOver()}}>
                    {title}
                </Typography>

                <Typography variant="body1" gutterBottom>
                Welcome to the skills section! As a computer science and mathematics student with a passion for software development and data analysis, 
                I possess a diverse skill set that enables me to approach problems from multiple angles. My background in computer science has equipped 
                me with fundamental CS skills and programming proficiency. Additionally, my background in mathematics has provided me with a strong foundation 
                in logical and systematic thinking, allowing me to tackle challenges with confidence. These skills, combined with my eagerness to learn and 
                collaborate with others, make me a valuable asset to any team.
                </Typography>

                <br/><br/>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" component="h6" gutterBottom className='text-heading'>
                            Programming Languages and Other Tools
                        </Typography>
                        <ul className='ul_first'>
                            <li>Python</li>
                                <ul className='ul_second'>
                                    <li>Pandas</li>
                                    <li>Django</li>
                                    <li>Flask</li>
                                </ul>
                            <li>Web Development</li>
                                <ul className='ul_second'>
                                    <li>JavaScript</li>
                                    <li>React</li>
                                    <li>HTML</li>
                                    <li>CSS</li>
                                </ul>
                            <li>C</li>
                            <li>SQL</li>
                            <li>Git</li>
                            <li>Java</li>
                            <li>R</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" component="h6" gutterBottom className='text-heading'>
                            Computer Science Fundamentals
                        </Typography>
                        <ul className='ul_first'>
                            <li>Data Structures</li>
                            <li>Design and Analysis of Algorithms</li>
                            <li>Databases</li>
                            <li>Object-Oriented Programming</li>
                            <li>Software Engineering</li>
                            <li>Data Analysis</li>
                            <li>Machine Learning</li>
                            <li>Operating Systems</li>
                            <li>Computer Architecture</li>
                            <li>Computer Networks</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" component="h6" gutterBottom className='text-heading'>
                            Soft Skills
                        </Typography>
                        <ul className='ul_first'>
                            <li>Problem Solving</li>
                            <li>Critical Thinking</li>
                            <li>Teamwork</li>
                            <li>Communication</li>
                            <li>Time Management</li>
                            <li>Adaptability</li>
                        </ul>
                    </Grid>
                </Grid>

                <Break/>
            </div>
            
        </div>
    );
}