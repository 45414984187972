import React, { } from 'react';
import '../../styles/sections.css';

import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Break from '../Break/Break';

export default function About() {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$%&!?';
    const noCaps_characters = 'abcdefghijklmnopqrstuvwxyz@#$%&!?';

    const original_title = 'About';

    const [title, setTitle] = React.useState(original_title);

    const handleMouseOver = () => {
        let iterations = 0;

        const interval = setInterval(() => {
            let new_title = '';

            for (let i = 0; i < original_title.length; i++) {
                
                if (i < iterations) {
                    new_title += original_title[i];
                } else {
                    if (original_title[i] === original_title[i].toUpperCase()) {
                        new_title += characters[Math.floor(Math.random() * characters.length)];
                    } else {
                        new_title += noCaps_characters[Math.floor(Math.random() * noCaps_characters.length)];
                    }
                }
            }

            setTitle(new_title);
            iterations += 1/3;

            if (iterations >= original_title.length) {
                clearInterval(interval);
            }
        }, 30);
    }       

    return (
        <div className='section' id='about'>
            
            <PersonIcon className='section-header-icon about-icon'/>

            <div className='section-content'>
                <Typography variant="h4" component="h4" gutterBottom className='text-heading' onMouseOver={() => {handleMouseOver()}}>
                    {title}
                </Typography>

                <Typography variant="body1" gutterBottom >
                Welcome to my personal website! 
                My name is Javi Barranco, and I'm currently studying a Double Degree in Computer Science and Mathematics at Universidad Autónoma de Madrid. 
                I'm originally from Málaga, Spain, where I completed my A Levels at an international British school.
                <br/><br/>
                I have a passion for software development and data analysis, and I'm excited to share some of my experiences and projects with you. 
                During my studies, I have gained experience in programming languages such as Python, C and Java. 
                In addition to my academic pursuits, I have worked on projects related to data analysis and machine learning, including analyzing and visualizing data using tools such as Pandas, NumPy, and Matplotlib.
                Additionally, I have worked on web development projects where I have learned JavaScript, CSS, and React, which has given me experience in front-end web development.
                I have also participated in several hackathons, where I collaborated with other developers to create innovative solutions to real-world problems.
                <br/><br/>
                In the future, I hope to pursue a career in software development or data analysis, where I can apply my skills to solve challenging problems and create value for users. 
                I am always eager to learn new technologies and collaborate with others on interesting projects.
                <br/><br/>
                Thank you for visiting my website, and please feel free to reach out to me with any questions or opportunities to collaborate.
                </Typography>

                <Break/>
            </div>
            
        </div>
    );
}